"use client";

import Geocoding from "@mapbox/mapbox-sdk/services/geocoding";
import { createContext, useContext, useRef } from "react";

import type { GeocodeService } from "@mapbox/mapbox-sdk/services/geocoding";
import type { ReactNode } from "react";

interface MapboxContext {
  accessToken: string;
  geocodeService: GeocodeService | null;
}

export const mapboxContext = createContext<MapboxContext | null>(null);

const useMapboxContext = () => {
  const context = useContext(mapboxContext);

  if (context === null) {
    throw new Error("useMapboxContext must be used within a MapboxProvider");
  }
  return context;
};

export const useMapboxToken = () => {
  const context = useMapboxContext();
  return context.accessToken;
};

export const useGeocodeService = () => {
  const context = useMapboxContext();
  return context.geocodeService;
};

export default function MapboxProvider({
  accessToken,
  children,
}: {
  accessToken: string;
  children: ReactNode;
}) {
  const geocodeServiceRef = useRef<GeocodeService | null>();

  if (geocodeServiceRef.current === undefined) {
    // Only init geocode service if mapbox token is non-empty
    geocodeServiceRef.current = accessToken ? Geocoding({ accessToken }) : null;
  }

  return (
    <mapboxContext.Provider value={{ accessToken, geocodeService: geocodeServiceRef.current }}>
      {children}
    </mapboxContext.Provider>
  );
}
