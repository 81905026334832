import { createContext, useContext } from "react";

import type { SubscriptionClient } from "subscriptions-transport-ws";

export interface RelayContext {
  subscriptionClient: SubscriptionClient;
}

export const relayContext = createContext<RelayContext | null>(null);

export const useRelayContext = () => {
  const context = useContext(relayContext);

  if (context === null) {
    throw new Error("useRelayContext must be used within a RelayContextProvider");
  }
  return context;
};
