import { createReducer } from "@reduxjs/toolkit";

import * as CLOUD_API from "@skydio/api_util/src/backends/cloud_api";

export interface DashboardState {
  stats: CLOUD_API.OrganizationStats;
  pilotStats: CLOUD_API.OrganizationPilotStats;
  storageStats: CLOUD_API.OrganizationStorageStats;
}

const initialState: DashboardState = {
  stats: {
    totalsList: [],
    averagesList: [],
  },
  pilotStats: {} as CLOUD_API.OrganizationPilotStats,
  storageStats: {} as CLOUD_API.OrganizationStorageStats,
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(
      CLOUD_API.modules.organizations.asyncThunks.fetchOrganizationStats.fulfilled,
      (state, { payload }) => {
        state.stats = payload;
      }
    )
    .addCase(
      CLOUD_API.modules.organizations.asyncThunks.fetchOrganizationPilotStats.fulfilled,
      (state, { payload }) => {
        state.pilotStats = payload;
      }
    )
    .addCase(
      CLOUD_API.modules.organizations.asyncThunks.fetchOrganizationStorageStats.fulfilled,
      (state, { payload }) => {
        state.storageStats = payload;
      }
    )
);

export default reducer;
