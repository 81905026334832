"use client";

import { useRef } from "react";
import { Provider } from "react-redux";

import createStore from "state/store";

import type { ReactNode } from "react";
import type { AppStore } from "state/store";

export default function StoreProvider({ children }: { children: ReactNode }) {
  const storeRef = useRef<AppStore>();
  if (!storeRef.current) {
    storeRef.current = createStore();
    // Expose Redux store to global window, for Playwright testing purposes
    if (typeof window !== "undefined") {
      window.__DEV_GET_REDUX_STORE = () => storeRef.current;
    }
  }

  return <Provider store={storeRef.current}>{children}</Provider>;
}
