import * as CLOUD_API from "@skydio/api_util/src/backends/cloud_api";
import { makeWrappedSelectors, makeWrappedThunks } from "@skydio/redux_util/src";

import type { AppState } from "./store";

export const getAuthSlice = (state: AppState) => state.auth;

const { selectors, asyncThunks } = CLOUD_API.modules.auth;

export const {
  isAuthenticated,
  wasAuthenticateRequested,
  getAuthenticateError,
  didAuthenticateSucceed,
} = makeWrappedSelectors(selectors, getAuthSlice);

export const { authAuthenticate } = makeWrappedThunks(asyncThunks, getAuthSlice);
