import { message } from "antd";
import { Environment, Network, Observable, RecordSource, Store } from "relay-runtime";
import { SubscriptionClient } from "subscriptions-transport-ws";

import {
  getCSRFAccessToken,
  graphqlFetcher,
  requestWithAuth,
} from "@skydio/api_util/src/backends/cloud_api/requests-browser";
import { FetchError } from "@skydio/api_util/src/common/http";
import { CloudErrorCode } from "@skydio/pbtypes/pbtypes/gen/cloud_api/cloud_error_code_pb";

import { CLOUD_API_URL, CLOUD_API_WS_URL, IS_DEV } from "app/env";

import type { GraphQLResponse, RequestParameters, Subscribable, Variables } from "relay-runtime";

// Establish a graphql over websocket client connection based on the
// "graphql-ws" websocket subprotocol defined here:
//
// https://github.com/apollographql/subscriptions-transport-ws/blob/master/PROTOCOL.md
//
// Client implementation of the above protocol is provided by the
// "subscriptions-transport-ws" library
// (https://github.com/apollographql/subscriptions-transport-ws). This library
// is self-described as no longer maintained and recommends using the
// "graphql-ws" library (https://github.com/enisdenjo/graphql-ws) instead.
// However, and this is confusing, the "graphql-ws" client library implements a
// different graphql over websockets protocol documented here
// (https://github.com/enisdenjo/graphql-ws/blob/master/PROTOCOL.md) under the
// "graphql-transport-ws" subprotocol.
//
// The python backend readily available for Graphene and included in the
// graphql-python repository on github is also called "graphql-ws"
// (https://github.com/graphql-python/graphql-ws). The server does implement the
// "graphql-ws" subprotocol, but is not compatible with the "graphql-ws" client
// library mentioned above.
//
// I would prefer to use the new "graphql-transport-ws" subprotocol, but haven't
// found a server library for Graphene and I don't want to re-implement myself.
// So I'm sticking with this for now.

// graphql-ws subprotocol client
export const makeSubscriptionClient = (onJwtExpired: () => void) =>
  new SubscriptionClient(
    `${CLOUD_API_WS_URL}/subscriptions`,
    {
      reconnect: true,
      lazy: true,
      connectionParams: () => ({
        "X-CSRF-TOKEN": getCSRFAccessToken(),
      }),
      connectionCallback: (error: Error[]) => {
        // Assume that a connection error is from an expired JWT. This callback should trigger a
        // token refresh if one is necessary, and should only do this if we were already authenticated
        // to avoid repeatedly calling this before login
        if (error) {
          onJwtExpired();
        }
      },
    }
    // NOTE(sam): We may need to revisit the websocket implementation here once we start doing
    // server-side rendering
  );

let reconnectTimeoutID: NodeJS.Timeout | null = null;
export const ensureSubscriptionClientConnected = (subscriptionClient: SubscriptionClient) => {
  // Cancel previous check, kick off a new one if multiple happen next to each other.
  if (reconnectTimeoutID !== null) {
    clearTimeout(reconnectTimeoutID);
  }

  // Set a timer to reconnect if we don't verify end-to-end websocket connectivity. This should only
  // get invoked if we cannot verify connectivity, however it is fine if it gets invoked and we are
  // connected, the method should essentially be idempotent and the resulting state is that we are
  // connected and fully subscribed
  reconnectTimeoutID = setTimeout(() => {
    // Calling close will with isForced=false and "reconnect" option set to true in the client will
    // close any existing websocket connections, open a new one, and restore all subscriptions.
    if (getCSRFAccessToken()) {
      subscriptionClient.close(false, true);
    }
  }, 1000);

  // Don't check the websocket if we aren't authenticated, this will just result in an
  // server/authentiation error trying to establish the websocket.  We use the presence of
  // the CSRF access token as a proxy for being authenticated.
  if (!getCSRFAccessToken()) {
    return;
  }

  // Check for a functioning connection.  The time subscription is the simplest that simply updates
  // the current time at the requested interval on the websocket.  This a poor mans ping/pong since
  // we don't have a proper application layer ping/pong exposed in the subscription-transport-ws
  // protocol.
  // (https://github.com/apollographql/subscriptions-transport-ws/blob/master/PROTOCOL.md).
  //
  // Consider in the future:
  //   - Leverage GQL_CONNECTION_KEEP_ALIVE which is defined in the protocol but not supported in
  //     our server implementation.  Could be an easy add.
  //   - Change the implementation to reset the websocket if we don't get a subscription update
  //     within a certain time period vs doing a one time ping/pong.
  const observable = subscriptionClient.request({
    query: "subscription ping { time( intervalSeconds: 1 ) }",
    operationName: "ping",
  });
  const { unsubscribe } = observable.subscribe({
    next: response => {
      // PONG!  This is the expected response if we are connected!
      if (response.data?.time) {
        if (reconnectTimeoutID) {
          clearTimeout(reconnectTimeoutID);
          reconnectTimeoutID = null;
        }
        unsubscribe();
      }
    },
    error: error => console.error(error),
  });
};

interface Query {
  operationName: string;
  variables: Variables;
}

interface JWTEnvironmentArgs {
  subscriptionClient: SubscriptionClient;
  // This callback should reset the authentication state and bounce the user back to login
  onJwtExpired: () => void;
  logQuery?: (query: Query) => void;
}

export default function makeEnvironment({
  subscriptionClient,
  logQuery,
  onJwtExpired,
}: JWTEnvironmentArgs) {
  async function fetchQuery(operation: RequestParameters, variables: Variables) {
    try {
      const response = await requestWithAuth(
        `${CLOUD_API_URL}/graphql`,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: operation.text,
            variables,
            operationName: operation.name,
          }),
        },
        graphqlFetcher
      );
      // In dev mode, send graphql errors prominently to the UI
      if (IS_DEV && response.errors) {
        response.errors.forEach((error: { message: string; path: string[] }) => {
          console.error(error);
          message.error({
            content: `${error.message}\n${error.path.join(".")}`,
            duration: 5,
          });
        });
      }
      logQuery?.({
        variables,
        operationName: operation.name,
      });
      return response;
    } catch (error) {
      if (
        error instanceof FetchError &&
        error.code &&
        parseInt(error.code) === CloudErrorCode.Enum.REFRESH_REQUIRED
      ) {
        // If this error has propagated up here it means the refresh token has expired
        onJwtExpired();
        return {
          errors: [{ message: "Refresh token has expired." }],
        };
      } else {
        return {
          errors: [{ message: String(error) }],
        };
      }
    }
  }

  function subscribe(request: RequestParameters, variables: Variables) {
    const subscribeObservable = subscriptionClient.request({
      query: request.text || undefined,
      variables,
      operationName: request.name,
    });
    // Important: Convert subscriptions-transport-ws observable type to Relay's
    return Observable.from(subscribeObservable as Subscribable<GraphQLResponse>);
  }

  const environment = new Environment({
    network: Network.create(fetchQuery, subscribe),
    store: new Store(new RecordSource(), { queryCacheExpirationTime: 5 * 60 * 1000 }),
  });

  return environment;
}
