import { createSlice } from "@reduxjs/toolkit";

import type { PayloadAction } from "@reduxjs/toolkit";
import type { Variables } from "relay-runtime";

export interface Query {
  operationName: string;
  variables: Variables;
}

type RelayState = {
  queryHistory: Query[];
};

const initialState = { queryHistory: [] } as RelayState;

const { reducer, actions: relayActions } = createSlice({
  name: "relay",
  initialState,
  reducers: {
    logQuery(state, { payload }: PayloadAction<Query>) {
      state.queryHistory = [...state.queryHistory, payload];
      while (state.queryHistory.length > 50) {
        state.queryHistory.shift();
      }
    },
  },
});

export { relayActions };
export default reducer;
